

























import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import PreviewDocumentViewModel
  from '@/vue-app/view-models/components/documents/preview-document-view-model';

@Component({ name: 'PreviewDocument' })
export default class PreviewDocument extends Vue {
  preview_document_view_model = Vue.observable(new PreviewDocumentViewModel());

  @Prop({ type: String, required: false })
  documentName?: string;

  @Prop({ type: File, required: false })
  file?: File;

  @Watch('file')
  onFileChange(file: File) {
    this.preview_document_view_model.setFile(file);
    this.preview_document_view_model.setPreviewLink(file);
  }
}
