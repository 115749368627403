export default class PreviewDocumentViewModel {
  preview_link: string | null = null;

  file?: File;

  is_loading = false;

  get is_image() {
    return this.file?.type.includes('image');
  }

  setPreviewLink = (file: File) => {
    this.preview_link = URL.createObjectURL(file);
  }

  setFile(file: File) {
    this.file = file;
  }
}
